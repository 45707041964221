<!-- This components is a middleware for recognizing trasforming course type -->
<template>
  <div v-if="isDataFetched">
    <NewSwapFromTemplate v-if="orderType === 'Wymiana'" />
    <NewPickupFromTemplate
      v-else-if="orderType === 'Zabranie'"
      specific-client
    />
    <NewOrderFromTemplate
      v-else
      is-from-client-app
    />
  </div>
</template>

<script>
import NewOrderFromTemplate from './NewOrderFromTemplate.vue'
import NewPickupFromTemplate from './NewPickupFromTemplate.vue'
import NewSwapFromTemplate from './NewSwapFromTemplate.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    NewOrderFromTemplate,
    NewPickupFromTemplate,
    NewSwapFromTemplate,
  },
  data: () => ({
    isDataFetched: false
  }),
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
    }),
    orderType () {
      return this.dialog.item.orderType
    },
  },
  created () {
    if (this.dialog.item.orderType === 'Zabranie') this.setDialogSize('small')
    const { order, swapOrder, client, anyContainer } = this.dialog.item
    const { id } = swapOrder || order || {}
    const getClient = this.getClient(client?.id)
    const promises = [getClient]
    if (id && !anyContainer) {
      const getOrder = this.getOrder({ id })
      promises.push(getOrder)
    }
    return Promise.all(promises)
      .then(() => { this.isDataFetched = true })
  },
  beforeDestroy () {
    this.clearSingleOrder()
  },
  methods: {
    ...mapActions({
      getOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      getClient: 'client/getSingleClient',
      setDialogSize: 'layout/setDialogSize',
    })
  }
}
</script>
