<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="addNewOrderForm"
        class="add-new-order-form"
        @submit.prevent="submitNewOrderForm"
      >
        <v-row>
          <v-col
            cols="6"
            class="pt-0 mt-n2"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <ChooseClientAddressPartial
                  v-if="clientSet || !orderTemplate.client"
                  ref="chooseClientAddressPartial"
                  :addresses="orderFromTemplateAddresses"
                  :order-data="order"
                  :course-type="course.courseType"
                  :show-address-form="showAddressForm"
                  :distance="straightLineDistance"
                  :specific-client="!!orderTemplate.client"
                  no-address-items-text="Brak lokalizacji zgodnych z adresem w zamówieniu"
                  :disable-suggested-order-price="isFormDisabled"
                  :order-from-template-data="orderTemplate"
                  @update="updateFormValue"
                  @addAddress="changeAddressFormStatus(true)"
                  @editAddress="changeAddressFormStatus(true, true)"
                />
              </v-col>

              <v-col
                v-show="showAddressForm"
                cols="12"
                class="p-0"
              >
                <AddressForm
                  ref="addressForm"
                  actions
                  :editing-address="chosenAddress"
                  :payment-type-visible="false"
                  @close="changeAddressFormStatus(false)"
                  @addNewAddress="addAddress($event, true)"
                  @editAddress="updateAddress"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="pt-0 mt-4"
          >
            <v-row>
              <v-col cols="12">
                <NewCoursePartial
                  :course-data="course"
                  :order-data="order"
                  :pickup-date="pickupDate"
                  is-simple-order-type
                  order-template
                  :disabled="isFormDisabled"
                  @update="updateFormValue"
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <NewPaymentPartial
                  ref="payment"
                  :payment-data="payment"
                  :course-type="course.courseType"
                  :client-type="clientType"
                  :location-discount="locationDiscount"
                  :distance="straightLineDistance"
                  :disabled="isFormDisabled"
                  @update="updateFormValue"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        :disabled="showAddressForm || isClientFormOpen || missingClientOrAdress || isFormDisabled"
        @click="submitNewOrderForm()"
      >
        Stwórz zlecenie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit, getNetValue, roundToDecimalPoint } from '../../utils'
import updateFormValue from '../../mixins/updateFormValue.vue'
import orderFormMixin from '../../mixins/orderFormMixin.vue'

export default {
  props: {
    isFromClientApp: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    updateFormValue,
    orderFormMixin, // changeAddressFormStatus, fetchClientAddresses, addAddress, updateAddress, addresses
    // order, course, payment, showAddressForm, , chosenAddress, locationDiscount, clientType
    // department, isProcessing, straightLineDistance, closeDialog, showSnackbar, watch: 'order.addressId'
  ],
  data: () => ({
    isClientFormOpen: false,
    clientSet: false,
    pickupDate: null,
  }),
  computed: {
    ...mapState({
      orderTemplate: state => state.layout.dialog.item,
      sidebar: state => state.layout.sidebar,
    }),
    missingClientOrAdress() {
      return !this.order.clientId && !this.order.addressId
    },
    addressQuery() {
      return this.orderTemplate.recipientStreet // needed in orderFormMixin
    },
    isFormDisabled () {
      return !this.order.addressId || !this.order.clientId
    }
  },
  watch: {
    'order.clientId' (id) {
      if (id) this.fetchClientAddresses()
    },
  },
  mounted () {
    if (this.isFromClientApp) {
      this.clientAppOrdersInit()
    } else {
      this.orderTemplatesInit()
    }
  },
  methods: {
    ...mapActions({
      addNewOrder: 'orders/addNewItem',
      getOrderTemplates: 'orderTemplates/getItems',
      getClientAppOrders: 'clientAppOrders/getItems',
      getSingleClient: 'client/getSingleClient',
      setSingleViewEntity: 'singleView/setSingleViewEntity',
    }),
    submitNewOrderForm () {
      if (this.$refs.addNewOrderForm.validate()) {
        const payment = { ...this.payment }
        payment.debrisNetValue = parseAsBasicUnit(payment.debrisNetValue)
        payment.debrisGrossValue = parseAsBasicUnit(payment.debrisGrossValue)
        payment.transportNetValue = parseAsBasicUnit(payment.transportNetValue)
        payment.transportGrossValue = parseAsBasicUnit(payment.transportGrossValue)
        payment.discount = parseAsBasicUnit(payment.discount)
        const pickupCourse = {
          ...this.course,
          courseType: undefined,
          dueDate: this.pickupDate
        }
        const params = {
          ...this.order,
          course: this.course,
          pickupCourse: this.pickupDate ? pickupCourse : undefined,
          payment,
        }
        if (this.isFromClientApp) {
          params.clientAppOrderId = this.orderTemplate.id
        } else {
          params.orderTemplateId = this.orderTemplate.id
        }
        if (this.course.courseType === 'Podstawienie') delete params.aggregateAmount

        this.addNewOrder({ params, endpoint: 'orders' })
          .then(() => {
            if (this.$route.name === 'defaultSingleView') {
              const item = {
                ...this.orderTemplate,
                transformed: true
              }
              this.setSingleViewEntity({
                tableName: this.isFromClientApp ? 'clientAppOrders' : 'orderTemplates',
                item
              })
            }
            if (this.isFromClientApp) {
              this.getClientAppOrders()
            } else {
              this.getOrderTemplates()
            }
            this.$refs.addNewOrderForm.reset()
            this.closeDialog()
          })
      } else {
        this.showSnackbar({
          type: 'error',
          message: ['Nie wszystkie pola wypełniono poprawnie']
        })
      }
    },
    orderTemplatesInit () {
      const {
        client, clientName, clientEmail, clientPhoneNumber, recipientPhoneNumber, recipientName, recipientStreet, recipientCity,
        price, startDate, endDate, debrisType, containerType, quantity, message, payer, recipientPostCode,
        invoiceCity, invoiceEmail, invoiceName, invoicePostCode, invoiceStreet, id, clientBdoNumber, invoiceNip, paymentDueDays
      } = this.orderTemplate

      if (client) {
        this.getSingleClient(client.id)
          .then(() => { this.clientSet = true })
      } else {
        this.clientSet = true

        this.$refs.chooseClientAddressPartial.showNewClientForm = true

        this.$refs.chooseClientAddressPartial.$refs.newClientPartial.client = {
          ...this.$refs.chooseClientAddressPartial.$refs.newClientPartial.client,
          name: clientName,
          phoneNumber: clientPhoneNumber,
          invoicePhoneNumber: clientPhoneNumber,
          bdoNumber: clientBdoNumber,
          email: clientEmail || invoiceEmail,
          clientType: invoiceNip ? 'Firma' : 'Osoba fizyczna',
          bdoType: 'Prywatny',
          invoiceBuyerName: invoiceName,
          invoiceAddress: invoiceStreet,
          invoicePostalCode: invoicePostCode,
          invoiceCity: invoiceCity,
          invoiceNip: invoiceNip,
          settlementType: invoiceName ? 'Faktura imienna' : 'Paragon',
          orderTemplateId: id,
          paymentType: 'Przelew',
          paymentDueDays: paymentDueDays
        }
        this.$refs.chooseClientAddressPartial.$refs.newClientPartial.nipQuery = invoiceNip
      }

      this.clientType = invoiceNip ? 'Firma' : 'Osoba fizyczna'

      this.pickupDate = this.checkAndChangePastDate(endDate)

      this.$refs.addressForm.address = {
        ...this.$refs.addressForm.address,
        phoneNumber: recipientPhoneNumber,
        contactName: recipientName,
        email: invoiceEmail || clientEmail,
        departmentId: this.department.id,
        address: {
          formattedAddress: `${recipientStreet || ''} ${recipientCity || ''} ${recipientPostCode || ''}`,
        }
      }

      this.order = {
        ...this.order,
        quantity,
        payerId: invoiceName ? payer?.id : undefined, // payerId is only required if invoice fields are filled
        userNotes: message,
        containerTypeId: containerType?.id,
        debrisTypeId: debrisType?.id
      }

      this.course = {
        ...this.course,
        dueDate: this.checkAndChangePastDate(startDate),
      }
      this.payment = {
        ...this.payment,
        paymentType: 'Online',
        paymentDueDays: 1,
        debrisNetValue: roundToDecimalPoint(getNetValue(+price / 100), 2),
        debrisGrossValue: roundToDecimalPoint(+price / 100, 2),
      }
    },
    clientAppOrdersInit () {
      const {
        debrisType, containerType, address, leaveDate, pickupDate, contact, client, payer
      } = this.orderTemplate

      this.pickupDate = pickupDate

      this.clientType = client?.clientType

      this.order = {
        ...this.order,
        phoneNumber: contact?.phoneNumber,
        clientId: client?.id,
        payerId: payer?.id,
        addressId: address?.id,
        containerTypeId: containerType?.id,
        debrisTypeId: debrisType?.id
      }

      this.course = {
        ...this.course,
        dueDate: this.checkAndChangePastDate(leaveDate),
      }
      this.payment = {
        ...this.payment,
        paymentType: 'Przelew',
        discount: address?.discount && address.discount / 100,
        discountJustification: address?.discountJustification
      }

      this.$nextTick(() => {
        this.clientSet = true
      })
    },
    checkAndChangePastDate(dateString) {
      const propDateObject = new Date(dateString)
      const todayDateObject = new Date()
      return propDateObject > todayDateObject ? dateString : todayDateObject.toISOString().split('T')[0]
    }
  }
}
</script>
