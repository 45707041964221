<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="newSwapForm"
        @submit.prevent="submitSwapForm"
      >
        <v-row class="mt-4">
          <v-col class="pt-0">
            <ChooseOrderPartial
              v-if="orderFetched"
              :order-data="order"
              :payment-data="previousPayment"
              :schedule="schedule"
              :distance="straightLineDistance"
              :swap-base-order="swapBaseOrder"
              hide-order
              swap
              prepopulate
              from-client-app
              :template="template"
              @update="addUpdatedProperty"
            />
          </v-col>
          <v-col class="pt-0">
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <NewCoursePartial
                  v-if="orderFetched"
                  :course-data="course"
                  :order-data="order"
                  swap
                  edit-mode
                  @update="addUpdatedProperty"
                />
              </v-col>
              <v-col class="pt-0">
                <NewPaymentPartial
                  v-if="orderFetched"
                  ref="payment"
                  course-type="Wymiana"
                  :client-type="clientType"
                  :payment-data="payment"
                  :location-discount="locationDiscount"
                  :distance="straightLineDistance"
                  @update="addUpdatedProperty"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        @click="submitSwapForm()"
      >
        Stwórz wymianę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit, getDistanceBetweenTwoPoints } from '../../utils'
import rules from '../../utils/validators'
import NewCoursePartial from './Partials/NewCourse'
import NewPaymentPartial from './Partials/NewPayment'
import ChooseOrderPartial from './Partials/ChooseOrder'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import updateFormValue from '../../mixins/updateFormValue.vue'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { Swap } from '../../models'
import set from 'lodash/set'

export default {
  components: {
    NewCoursePartial,
    NewPaymentPartial,
    DialogFormWrapper,
    ChooseOrderPartial
  },
  mixins: [updateFormValue, afterFormSubmitted],
  data () {
    return {
      rules,
      locationDiscount: null,
      orderFetched: false,
      destinationCoordinates: null,
      ...new Swap(),
      clientType: null,
      updatedParams: {},
      hasSwapOrder: false
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      schedule: state => state.layout.dialog.data.schedule,
      template: state => state.layout.dialog.item,
      swapOrder: state => state.order.swapOrder,
      isProcessing: state => state.orders.isProcessing
    }),
    straightLineDistance () {
      const baseCoordinates = { baseLat: this.department.lat, baseLng: this.department.lng }
      return this.destinationCoordinates ? getDistanceBetweenTwoPoints(baseCoordinates, this.destinationCoordinates) : 0
    },
    swapBaseOrder () {
      return this.hasSwapOrder ? this.swapOrder : null
    },
    previousPayment() {
      return this.swapOrder?.payment || {}
    }
  },
  mounted () {
    const {
      address, contact, pickupDate, containerType, debrisType, anyContainer, swapOrder, client
    } = this.template

    this.clientType = client.clientType

    this.order = {
      ...this.order,
      orderId: swapOrder?.id,
      phoneNumber: contact?.phoneNumber,
      pickupContainerTypeId: containerType?.id,
      debrisTypeId: debrisType?.id,
      addressId: address?.id,
      anyContainer
    }

    this.payment = {
      ...this.payment,
      paymentType: 'Przelew',
      discount: address?.discount / 100,
      discountJustification: address?.discountJustification,
    }

    this.course.dueDate = pickupDate || new Date().toISOString().substring(0, 10)
    if (swapOrder && !anyContainer) {
      this.hasSwapOrder = true
      this.getSwapOrder(swapOrder.id)
        .then(() => {
          this.orderFetched = true
        })
    } else {
      this.$nextTick(() => {
        this.orderFetched = true
      })
    }
  },
  methods: {
    ...mapActions({
      getSwapOrder: 'order/getSwapOrder',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar',
      addNewSwapOrder: 'orders/addNewItem',
    }),
    addUpdatedProperty (data) {
      this.updateFormValue(data)
      set(this.updatedParams, data.key, data.value)
    },
    submitSwapForm () {
      const isFormValid = this.$refs.newSwapForm.validate()

      if (isFormValid) {
        const {
          debrisTypeId,
          containerTypeId,
          pickupContainerTypeId,
          bdoNumber,
          anyContainer,
          addressId,
          ...orderData
        } = this.order
        const { userNotes, leaveNotes, ...courseData } = this.course

        const payment = { ...this.payment }
        payment.debrisNetValue = parseAsBasicUnit(payment.debrisNetValue)
        payment.debrisGrossValue = parseAsBasicUnit(payment.debrisGrossValue)
        payment.discount = parseAsBasicUnit(payment.discount)

        const params = {
          ...orderData,
          ...courseData,
          payment,
          pickupCourse: {
            bdoNumber,
            userNotes,
            anyContainer,
            addressId: anyContainer ? addressId : undefined,
            containerTypeId: pickupContainerTypeId
          },
          leaveCourse: {
            debrisTypeId,
            containerTypeId,
            userNotes: leaveNotes
          },
          clientAppOrderId: this.template.id
        }

        this.addNewSwapOrder({ endpoint: 'orders/createSwap', params })
          .then(() => {
            const { order, course, payment } = new Swap()
            this.order = order
            this.course = course
            this.payment = payment
            this.afterFormSubmitted('Utworzono wymianę')
          })
      } else {
        this.showSnackbar({ type: 'error', message: ['Nie wszystkie pola wypełniono poprawnie lub kontener nie jest dostępny'] })
      }
    }
  }
}
</script>
