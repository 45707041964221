<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="addNewPickupForm"
        v-model="isFormValid"
        @submit.prevent="submitPickupForm()"
      >
        <v-row class="mt-4">
          <v-col cols="12">
            <!-- there's no payment in template object -->
            <ChooseOrderPartial
              v-if="orderFetched"
              :order-data="order"
              :payment-data="{}"
              @update="updateFormValue"
              specific-client
              prepopulate
              from-client-app
              :template="template"
            />
          </v-col>
          <v-col
            cols="12"
            class="pt-0"
          >
            <NewCoursePartial
              :course-data="course"
              :order-data="order"
              @update="updateFormValue"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        @click="submitPickupForm()"
      >
        Dodaj zabranie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import NewCoursePartial from './Partials/NewCourse'
import ChooseOrderPartial from './Partials/ChooseOrder'
import updateFormValue from '../../mixins/updateFormValue.vue'
import { mapState, mapActions } from 'vuex'
import { Pickup } from '../../models'

export default {
  components: {
    NewCoursePartial,
    DialogFormWrapper,
    ChooseOrderPartial
  },
  mixins: [updateFormValue],
  data () {
    return {
      isFormValid: true,
      orderFetched: false,
      ...new Pickup()
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.courses.isProcessing,
      template: state => state.layout.dialog.item,
    })
  },
  mounted () {
    const {
      address, contact, pickupDate, containerType, anyContainer, order
    } = this.template

    this.order = {
      ...this.order,
      orderId: order?.id,
      phoneNumber: contact?.phoneNumber,
      pickupContainerTypeId: containerType?.id,
      addressId: address?.id,
      anyContainer
    }

    this.course.dueDate = pickupDate || new Date().toISOString().substring(0, 10)
    this.$nextTick(() => {
      this.orderFetched = true
    })
  },
  methods: {
    ...mapActions({
      addNewPickup: 'courses/addNewItem',
      closeDialog: 'layout/closeDialog',
    }),
    submitPickupForm () {
      if (this.$refs.addNewPickupForm.validate()) {
        const { pickupContainerTypeId: containerTypeId, ...order } = this.order
        const params = {
          ...order,
          ...this.course,
          containerTypeId,
          addressId: order.anyContainer ? order.addressId : undefined,
          clientAppOrderId: this.template.id
        }
        this.addNewPickup({ params, endpoint: 'courses' })
          .then(() => {
            this.closeDialog()
          })
      }
    }
  }
}
</script>
